import moment from 'moment'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { useAPIRequest } from '~/composables/api/useAPIRequest'
import type { EstateDetailInterface } from '~/types/api/EstateDetail'
import type { EstateListing } from '~/types/api/EstateListing'
import getEstateTrackingData from '~/utils/tracking/getEstateTrackingData'
import trackGtmEvent from '~/utils/tracking/tackGtmEvent'

type AnyEstateData = EstateDetailInterface | EstateListing

const preRenderAffectedCookies = [
  'dd8ec48f-4863-413d-8447-8b8b78e329cb', // /
  'e671677f-2089-41c2-b106-92ca3f224f27', // /en
  '7c428eb6-356b-4628-8b80-e5997fc8954c', // /nase-sluzby
  'f087c9bc-f876-4c99-b737-8c2190347d13', // /en/our-services
  'c9f7f483-a353-4d42-a1e8-506d3bd62ffe', // /o-nas
  '09b23be1-3abb-4492-93df-933892317248', // /en/about-us
  '2de3f84d-a163-46eb-aa42-dfa364d7c90c', // /kontakt
  '0ca15b56-f4ce-4c56-8ce8-a87813b2d05b', // /en/contact
]

export default defineStore('favoritesStore', () => {
  const { t } = useI18n()

  const favoriteCookie = useCookie('favorite-cookie', {
    expires: moment().add(2, 'years').toDate(),
    default: () => 'default',
  })
  const favoriteItems = useCookie(`favorite-items`, {
    expires: moment().add(2, 'years').toDate(),
    default: () => [] as (string | number)[],
  })
  const pendingItemsIds = ref({}) as Ref<Record<number, boolean>>

  const cookie = computed(() => {
    // this fixes: prerendered cookie was the same for all users
    if (favoriteCookie.value === 'default') {
      // eslint-disable-next-line no-console
      console.debug('Favorites: default cookie detected, generating new one')
      favoriteCookie.value = uuidv4()
    }

    // Also, we need to check if the cookie is in the list of affected cookies, those that were prerendered
    if (preRenderAffectedCookies.includes(favoriteCookie.value)) {
      // eslint-disable-next-line no-console
      console.debug('Favorites: affected cookie detected, generating new one')
      favoriteCookie.value = uuidv4()
    }

    return favoriteCookie.value
  })

  function init() {
    useAPIRequest(`/property-favourites/list`, {
      params: {
        cookie: cookie.value,
      },
    }).then((response) => {
      if (response && Object.keys(response).length > 0) {
        favoriteItems.value = Object.keys(response)
      } else {
        favoriteItems.value = []
      }
    })
  }

  function add(estateData: AnyEstateData) {
    if (pendingItemsIds.value[estateData.id]) return

    pendingItemsIds.value[estateData.id] = true

    useAPIRequest(`/property-favourites/add/${cookie.value}/${estateData.id}`, {
      method: 'POST',
    })
      .then((response) => {
        if (!response.status) {
          showToast({
            title: t('pages.favorites.addErrorTitle'),
            message: response.error || response.message,
            color: 'danger',
          })

          return
        }

        favoriteItems.value = [...favoriteItems.value, estateData.id.toString()]

        trackGtmEvent(
          'pridanie_medzi_oblubene',
          getEstateTrackingData(estateData)
        )

        showToast({
          title: t('pages.favorites.addSuccessTitle'),
          message: t('pages.favorites.addSuccessMessage', {
            _0: estateData.name,
          }),
        })
      })
      .catch((error) => {
        showToast({
          title: t('pages.favorites.addErrorTitle'),
          message: error.message,
          color: 'danger',
        })
      })
      .finally(() => {
        delete pendingItemsIds.value[estateData.id]
      })
  }

  function remove(estateData: AnyEstateData) {
    if (pendingItemsIds.value[estateData.id]) return

    useAPIRequest(
      `/property-favourites/remove/${cookie.value}/${estateData.id}`,
      {
        method: 'POST',
      }
    )
      .then((response) => {
        if (!response.status) {
          showToast({
            title: t('pages.favorites.removeErrorTitle'),
            message: response.error || response.message,
            color: 'danger',
          })

          return
        }

        favoriteItems.value = favoriteItems.value.filter(
          (id) => id.toString() !== estateData.id.toString()
        )

        showToast({
          title: t('pages.favorites.removeSuccessTitle'),
          message: t('pages.favorites.removeSuccessMessage', {
            _0: estateData.name,
          }),
        })
      })
      .catch((error) => {
        showToast({
          title: t('pages.favorites.removeErrorTitle'),
          message: error.message,
          color: 'danger',
        })
      })
      .finally(() => {
        delete pendingItemsIds.value[estateData.id]
      })
  }

  function toggle(estateData: AnyEstateData) {
    if (isInFavorites(estateData.id)) {
      remove(estateData)
    } else {
      add(estateData)
    }
  }

  function isInFavorites(estateId: string | number) {
    if (!favoriteItems.value) return false
    return favoriteItems.value.includes(estateId.toString())
  }

  const itemsCount = computed(() =>
    favoriteItems.value ? favoriteItems.value.length : 0
  )

  return {
    init,
    cookie,
    add,
    remove,
    toggle,
    isInFavorites,
    pendingItemsIds,
    favoriteItems,
    itemsCount,
  }
})
